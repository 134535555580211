import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 29; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/13-feb-24-1/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function KhelMela2024() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Khel Mela</h1>

        <p>
          The Primary and Pre-Primary departments of Chinmaya Vidyalaya recently
          held their Sports Day, Khel Mela.
          <br />
          <br />
          Parakram, the leopard mascot representing agility, delighted the
          children with its presence.
          <br />
          <br />
          The event began with the students taking an inspiring Oath of
          Sportsmanship under the leadership of the Sports Captain.
          <br />
          <br />
          Ms. Archana Soni, the Principal of the Vidyalaya address the
          participants and spectators and highlighted the importance of
          integrity and fair play.
          <br />
          <br />
          Pre-Primary students took the first step with a Novelty Walk,
          spreading the message of environmental conservation and green living.
          From Flat Races to 'Clear the Track', the little athletes from Nursery
          and Prep showcased their speed and agility with enthusiasm. An
          innovative Caterpillar Race gave the lesson of the value of team work
          and collaboration.
          <br />
          <br />
          Classes I and II displayed incredible energy in Flat Races and Relay
          Races, representing their houses with pride and determination. While
          classes III to IV participated in various running events, including
          Flat Races, Relay Races, and the exciting Hoopla Skipping Race.
          <br />
          <br />
          The teachers and facility staff joined in the fun with their own
          sprinting and fun-filled races, showing their support for the
          students' efforts.
          <br />
          <br />A heartfelt vote of thanks from the Primary Headmistress, Ms.
          Archana Gaur expressed gratitude to all participants, organizers, and
          supporters.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
